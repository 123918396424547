.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a4;
  z-index: 100;
}
.block-scrolling {
  overflow: hidden;
}
.close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 102;
  background-color: transparent;
  color: white;
  padding: 5px;
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  background-color: #6e6e6e7b;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
