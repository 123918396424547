.title {
  font-size: 2rem;
  margin: 20px auto;
  width: fit-content;
  color: red;
}

.card {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: fit-content;
  margin: auto;
}
.card .arrow {
  color: red;
  background-color: #ddd;
  transition: 0.2s;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.card .arrow:hover {
  background-color: #bbb;
}
.swiper {
  flex: 1;
  display: flex;
  align-items: center;
  margin: auto;
  gap: calc(15% / 10);
  overflow: hidden;
}

.client {
  width: 100px;
  min-width: calc(85% / 10);
}

.client img {
  display: block;
  width: 100%;
  transition: 0.3s;
}
@media (max-width: 1000px) {
  .swiper {
    gap: calc(15% / 8) !important;
  }
  .client {
    min-width: calc(85% / 8) !important;
  }
}
@media (max-width: 768px) {
  .swiper {
    gap: calc(15% / 6) !important;
  }
  .client {
    min-width: calc(85% / 6) !important;
  }
}
@media (max-width: 620px) {
  .swiper {
    gap: calc(15% / 4) !important;
  }
  .client {
    min-width: calc(85% / 4) !important;
  }
}
@media (max-width: 440px) {
  .swiper {
    gap: calc(15% / 2) !important;
  }
  .client {
    min-width: calc(85% / 2) !important;
  }
}
