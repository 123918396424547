.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: var(--container-padding);
  min-height: calc(100vh - 128px);
}
.content .title {
  margin: 40px 0;
  position: relative;
  font-size: 2.8rem;
  color: var(--secondary-color);
}
.content .greeting {
  color: white;
  font-size: 2.1rem;
}
.content .title::before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 25%;
  height: 4px;
  background-color: var(--primary-color);
}
.content p {
  color: var(--primary-color);
  margin: 20px 0;
}
.content a {
  margin: 30px auto;
  padding: 6px 25px;
  width: fit-content;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 100px;
  font-size: 1.1rem;
  font-weight: 600;
  display: block;
  line-height: 1.5;
  cursor: pointer;
}
.image {
  min-width: 40%;
  max-width: 60%;
  animation: up-down 4s linear infinite;
}

.image img {
  width: 100%;
}
@keyframes up-down {
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}
@media (max-width: 1000px) {
  .landing-page {
    flex-direction: column-reverse;
    gap: unset !important;
  }
  .title {
    margin-top: 0 !important;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 2.1rem !important;
  }
  .greeting {
    font-size: 1.7rem !important;
  }
}
