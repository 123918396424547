.header {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
}
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--container-padding);
  box-shadow: var(--main-box-shadow);
  background-color: var(--header-color);
}
.logo {
  width: 120px;
  display: block;
  padding: 8px 15px;
  background-color: white;
  border-radius: 100px;
}
.logo img {
  display: block;
  width: 100%;
}
.nav ul {
  display: flex;
  gap: 20px;
  font-size: 1.2rem;
  transition: 0.3s;
}
.nav ul li a {
  transition: 0.2s;
  color: white;
}

.nav ul li:hover a {
  color: var(--main-color);
}
.menu-bar {
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.2s;
  display: none;
  color: white;
}

@media (max-width: 768px) {
  .nav ul {
    position: absolute;
    left: 0;
    top: 65px;
    width: 100%;
    flex-direction: column;
    gap: 0 !important;
    background-color: white;
    animation: show-nav 0.2s;
    transform-origin: 50% 0;
  }
  .nav ul li a {
    display: block;
    padding: 10px;
    color: var(--secondary-color);
    border-bottom: 1px solid var(--secondary-color);
  }

  .menu-bar {
    display: block;
  }
}
.hide-nav {
  animation: hide-nav 0.2s both !important;
}
@keyframes show-nav {
  0% {
    transform: scaleY(0);
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
@keyframes hide-nav {
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}
