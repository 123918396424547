.services {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  justify-items: center;
}

@media (max-width: 420px) {
  .services {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
  }
}
