.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -100;
  background: linear-gradient(
    to bottom,
    var(--main-color),
    var(--gradient-color)
  );
}
