.backdrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 0;
  gap: 10px;
}
.backdrop::-webkit-scrollbar {
  display: none;
}
.active-image {
  flex: 1;
  max-width: 600px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  z-index: 101;
}
.active-image img {
  width: 100%;
  display: block;
  object-fit: cover;
  cursor: zoom-in;
  transition: 0.2s;
}
.zoom-in {
  width: 100vh !important;
  cursor: zoom-out !important;
}
.navigator {
  padding: 10px;
  background-color: #6e6e6e7b;
  overflow: auto;
  width: 100%;
  min-height: 120px;
}
.navigator::-webkit-scrollbar {
  display: none;
}
.swiper {
  display: flex;
  gap: 10px;
  width: fit-content;
  margin: auto;
}
.image {
  width: 100px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 4px;
  overflow: hidden;
}
.image:hover {
  transform: scale(0.97);
  border: 2px solid white;
}
.active {
  border: 3px solid white !important;
}
.image > img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
