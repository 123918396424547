.service {
  text-align: center;
  background-color: var(--main-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: var(--main-box-shadow);
  max-width: 600px;
}
.service img {
  width: 40%;
  transition: 0.5s;
}
.service:hover img {
  transform: scale(0.9);
}
.service h1 {
  color: var(--secondary-color);
  margin: 20px 0;
}
.service .description {
  color: var(--primary-color);
}
