.main-title {
  padding: 7px 18px;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  width: fit-content;
  position: relative;
  margin: 20px auto 40px;
  user-select: none;
  font-size: 2rem;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 1;
}

.main-title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  z-index: -1;
}

.main-title::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  z-index: -1;
}

.main-title:hover {
  transition-delay: 0.5s;
  transition-duration: 0.3s;
  color: white;
}
.main-title:hover::after {
  animation: right-fill 0.5s linear both;
}
.main-title:hover::before {
  animation: left-fill 0.5s linear both;
}
@keyframes right-fill {
  40% {
    right: 0;
    height: 8px;
    width: 8px;
  }
  100% {
    right: 0;
    border-radius: 0;
    height: 100%;
    width: calc(50% + 1px);
  }
}
@keyframes left-fill {
  40% {
    left: 0;
    height: 8px;
    width: 8px;
  }
  100% {
    left: 0;
    border-radius: 0;
    height: 100%;
    width: 50%;
  }
}
