.portfolio > div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  cursor: pointer;
  gap: 10px;
}

.image img {
  width: 100%;
  display: block;
}
.image {
  position: relative;
}
.image::before {
  content: "Show this work";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff784774;
  transition: 0.2s;
  opacity: 0;
  color: white;
  font-size: 2rem;
}
.image:hover::before {
  opacity: 1;
}
