body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --main-color: #241a6f;
  --secondary-color: #ff7947;
  --header-color: #5396c8;
  --gradient-color: #0f054c;
  --primary-color: #7a7b8a;
  --container-padding: 10px 5%;
  --section-padding: 80px;
  --main-box-shadow: 0 0 10px var(--gradient-color);
}
