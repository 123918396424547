footer {
  background-color: #191919;
}
.body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: flex-start;
  gap: 20px;
  padding: var(--container-padding);
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
}
@media (max-width: 500px) {
  .body {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .us {
    text-align: center;
  }
  .us span {
    margin: 0 auto;
  }
}
.us {
  max-width: 450px;
}
.us span {
  width: 120px;
  display: block;
  padding: 8px 15px;
  background-color: white;
  border-radius: 100px;
}
.us span img {
  display: block;
  width: 100%;
}
.us p {
  color: var(--primary-color);
  margin: 20px 0;
  line-height: 1.8;
}
.contact-us h2 {
  color: white;
  font-size: 2rem;
}
.contact-us .links {
  display: grid;
  grid-template-columns: repeat(3, 50px);
  gap: 10px;
  margin: 20px 0;
}
.contact-us .links a {
  padding: 10px;
  background-color: #5555559b;
  color: var(--primary-color);
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}
.links .instagram:hover {
  background: linear-gradient(45deg, #f58529, #dd2a7b, #8134af);
}
.links .facebook:hover {
  background-color: #1877f2;
}
.links .tiktok:hover {
  background-color: #ee1d52;
}
.links .whatsapp:hover {
  background-color: #25d366;
}
.links .twitter:hover {
  background-color: #1da1f2;
}
.contact-us .links button svg {
  font-size: 20px;
}
.payment {
  display: grid;
  grid-template-columns: repeat(2, 70px);
  gap: 10px;
  align-items: center;
}
.payment img {
  width: 100%;
}
.copyright {
  border-top: 2px solid #5555559b;
  text-align: center;
  font-size: 1.1rem;
  color: white;
  padding: 20px 20px;
}
.copyright span {
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .body {
    grid-template-columns: repeat(2, 1fr);
  }
  .copyright {
    font-size: unset;
  }
}
