a {
  text-decoration: none;
}

ul {
  list-style: none;
}
img {
  user-select: none;
}
html {
  scroll-behavior: smooth;
}

section {
  padding: var(--container-padding);
  padding-top: var(--section-padding);
  padding-bottom: var(--section-padding);
}

.text-nowrap {
  white-space: nowrap !important;
}
